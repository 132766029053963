@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@500&display=swap');
@import url('https://fonts.cdnfonts.com/css/abel-pro');

body {
  font-family: 'Roboto', sans-serif;
  background-color: #eaeaea;
  perspective: 1px;
  overflow-x: hidden;
  overflow-y: auto;
}
@media (min-width: 1050px) and (max-width: 2000px) {
  .page {
    width: 950px !important;
  }
}
.main-container {
  display: flex;
  flex-direction: column;
  min-height: 75vh;
}
.centered {
  margin-left: auto;
  margin-right: auto;
}
.page {
  width: 92%;
  margin-top: 0px;
  flex-grow: 1;
}
.content-box {
  font-size: 18px;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}



.footer-small-screen {
    display: none;
}

.footer-large-screen {
    display: block;
}

@media (max-width: 600px) {
    .footer-small-screen {
        display: block;
    }
    .footer-large-screen {
        display: none;
    }
}




.ff {
  width: 92%;
  font-size: 18px;
}
@media (min-width: 1050px) and (max-width: 2000px) {
  .ff {
    width: 950px !important;
  }
}
.fff {
  margin-right: 20px;
  margin-left: 20px;
}
header h1 {
  text-align: center;
  font-family: 'Playfair Display', serif;
  font-weight: 500;
  margin-top: 45px;
  font-size: 47px;
  letter-spacing: 2px;
  margin-bottom: 35px;
  color: black;
  padding-right: 20px;
  padding-left: 20px;
}
@media only screen and (max-width: 768px) {
  header h1 {
    font-size: 33px !important;
  }
}
.header-link,
.header-link:hover,
.header-link:focus,
.header-link:active {
  text-decoration: none;
  color: inherit;
}
nav {
  display: flex;
  justify-content: center;
  gap: 5px;
  flex-wrap: wrap;
padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 0px;
}
.nav-buttona {
  padding: 10px 15px;
  background: none;
  color: black;
  border: none;
  font-size: 18px;
  font-family: 'Dosis', sans-serif;
  letter-spacing: 5px;
  cursor: pointer;
  text-decoration: none;
  margin: 4px;
}
@media screen and (max-width: 768px) {
  .nav-buttona:hover {
    color: black !important;
  }
  .nav-buttona:active {
    color: black !important;
  }
}
.nav-buttona:hover {
  color: red;
}
.nav-buttona:active {
  color: red;
}
.nav-buttonb {
  padding: 10px 15px;
  background: none;
  color: black;
  border: none;
  font-size: 18px;
  font-family: 'Dosis', sans-serif;
  letter-spacing: 5px;
  cursor: pointer;
  text-decoration: none;
  margin: 4px;
}
@media (max-width: 768px) {
  .nav-buttona, .nav-buttonb {
    margin: 2px;
  }
}
.nav-buttonb:hover {
  color: red;
}
.nav-buttonb:active {
  color: red;
}
.icon-link-nav {
  font-size: 20px;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
}
.nav-link-line {
  display: block;
  height: 2px;
  background-color: red;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.3s ease;
  margin-top: 5px;
  width: calc(100% - 5px);
}
@media screen and (max-width: 768px) {
  .nav-buttona:hover .nav-link-line,
  .nav-buttonb:hover .nav-link-line {
    transform: scaleX(0) !important;
  }
}
.nav-buttona:hover .nav-link-line,
.nav-buttonb:hover .nav-link-line {
  transform: scaleX(1);
}
.nav-link-active-line {
  display: block;
  position: relative;
  height: 2px;
  background-color: black;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.3s ease;
  margin-top: -2px;
  width: calc(100% - 5px);
}
.nav-buttona.active:hover .nav-link-active-line,
.nav-buttona.active:focus .nav-link-active-line,
.nav-buttona.active:active .nav-link-active-line {
  transform: scaleX(1);
}
.nav-buttona.active .nav-link-active-line {
  transform: scaleX(1);
}
.nav-link-active-lineicon {
  display: block;
  position: relative;
  height: 2px;
  background-color: black;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.2s ease;
  margin-top: -2px;
  width: calc(100% - 5px);
}
.nav-buttonb:active .nav-link-active-lineicon {
  transform: scaleX(1);
  background-color: black;
}
.nav-button:active {
  background-color: #ffffff;
  cursor: pointer;
}
@media (max-width: 768px) {
  .text {
    font-size: 18px !important;
  }
  .textlabel {
    font-size: 18px !important;
  }
}
.text {
  font-size: 21px;
  margin-top: 16px;
  margin-bottom: 16px;
  color: black;
  font-family: 'Abel Pro', sans-serif;
  text-align: justify;
}
.textlabel {
  font-family: 'Abel Pro', sans-serif;
  font-size: 21px;
}
footer {
  margin-top: 25px;
  padding-bottom: 0px;
  margin-bottom: -3px;
  padding-top: 10px;
  width: 100%;
  color: gray !important;
  font-size: 12px;
  text-align: center;
}
.row {
  justify-content: center;
  margin-left: 0;
  margin-right: 0;
}
header h3 {
  text-align: center;
  font-family: 'Roboto', sans-serif !important;
  color: black;
  font-size: 23px;
  margin-bottom: 20px;
}
.logoinfo {
  text-align: center;
  font-weight: bold;
  font-family: 'Roboto', sans-serif !important;
  color: black;
  font-size: 20px;
  margin-bottom: 30px;
}
.gallery img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  margin-bottom: 35px;
}
@media (max-width: 1050px) {
  .gallery img {
    margin-bottom: 25px;
  }
}
/*
@media (max-width: 768px) {
  .logos {
    width: 60% !important;
  }
}
.logos {
  width: 600px;
}
*/
.logogallery img {
  width: 300px;
  height: auto;
  display: block;
  margin: 0 auto;
  margin-bottom: 15px;
}
.founder img {
  width: 100%;
  height: auto;
  max-width: 100%;
  display: block;
  margin-left: 0;
  margin-right: 0;
}
.info {
  margin-top: -5px;
  text-align: center;
  color: black;
  font-size: 21px;
  letter-spacing: 1px;
  margin-bottom: 28px;
}
.contact-form {
  margin-left: auto;
  margin-right: auto;
  margin-top: 35px;
  margin-bottom: 35px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%; 
}
@media (min-width: 730px) and (max-width: 2000px) {
  .contact-form {
    width: 600px !important;
  }
}
.contact-form input {
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #dcdcdc;
  transition: border-color 0.3s ease-in-out;
  resize: none;
}
.contact-form textarea {
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #dcdcdc;
  transition: border-color 0.3s ease-in-out;
  resize: none;
  height: 150px;
}
.contact-form input,
.contact-form textarea {
  font-family: 'Roboto', sans-serif;
}
.contact-form input:focus,
.contact-form textarea:focus {
  outline: none;
  border-color: #555;
}
.contact-form input[type="submit"] {
  padding: 10px 15px;
  background-color: #dcdcdc;
  border-radius: 4px;
  border: none;
  font-size: 16px;
  font-weight: 300;
  cursor: pointer;
  text-decoration: none;
  color: black;
  letter-spacing: 2px;
  width: auto;
  margin: 0 auto;
}
.contact-form input[type="submit"]:hover {
  background-color: #ffffff;
  cursor: pointer;
}
.success-message {
  color: green;
  font-weight: bold;
  text-align: center;
  opacity: 0;
  transform: translateY(20px);
  animation: slideUpFadeIn 1s forwards;
  margin-top: 7px;
  margin-bottom: 10px;
}
@keyframes slideUpFadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.contact-form input[type="submit"]:active {
  transform: scale(0.90);
  transition: transform 0.1s ;
}
.contact-form input.error,
.contact-form textarea.error {
  border: 2px solid red;
}
.event-list {
  display: flex;
  flex-wrap: wrap;
  font-size: 18px;
}
.event-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}
.year {
  width: 60px;
  text-align: left;
  font-weight: bold;
}
.event-description {
  flex: 3;
  text-align: left;
  padding-left: 0px;
  text-align: justify;
}
.events h4 {
  font-size: 18px;
  margin-top: 35px;
  margin-bottom: 15px;
}
.bioInfo {
  font-size: 18px;
  margin-bottom: 10px;
}
.availability {
  margin-top: 10px;
}
.mosaic-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
}
@media (max-width: 767px) {
  .mosaic-container {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}
.mosaic-item {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
}
.image-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s;
}
.mosaic-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
.hover-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
}
.image-wrapper:hover .hover-overlay {
  opacity: 1;
}
.hover-overlay span {
  color: white;
  font-size: 21px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 1px;
}
@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.image-wrapper:hover .hover-overlay span {
  animation: slideInFromBottom 0.3s forwards;
}
.mosaic-container2 {
  column-count: 3;
  column-gap: 20px;
  width: 100%;
}
@media (max-width: 767px) {
  .mosaic-container2 {
    column-count: 1;
    column-gap: 40px;
  }
}
.mosaic-item2 {
  position: relative;
  width: 100%;
  break-inside: avoid;
  overflow: hidden;
}
.image-wrapper2 {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s;
}
.mosaic-item2 img {
  width: 100%;
  height: auto;
  object-fit: contain;
  display: block;
}
.mosaic-item2:not(:last-child) {
  margin-bottom: 20px;
}
.hover-overlay2 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
}
.image-wrapper2:hover .hover-overlay2 {
  opacity: 1;
}
.hover-overlay2 span {
  color: white;
  font-size: 21px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 1px;
}
@keyframes slideInFromBottom2 {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.image-wrapper2:hover .hover-overlay2 span {
  animation: slideInFromBottom2 0.3s forwards;
}
.linksContainer {
  margin: 0;
  padding: 0;
}
.refinedLink {
  font-family: 'Roboto', sans-serif;
  color: black;
  text-decoration: none;
  font-weight: 600;
  border-bottom: none;
  display: inline-flex;
  align-items: center;
  margin-top: 0;
  margin-bottom: 20px;
  gap: 5px;
  outline: none;
  background-color: transparent;
  border: none;
  padding-left: 0px;
}

.refinedLink:hover {
  border-color: #5a4a72;
}
@media screen and (max-width: 768px) {
  .linkArrow {
    display: none !important;
  }
}
.linkArrow {
  font-size: 15px !important;
  transition: transform 0.3s;
  transform: rotate(-45deg);
}
.refinedLink:hover .linkArrow {
  transform: translate(4px, -4px) rotate(-45deg);
}
@media screen and (max-width: 768px) {
  .linkText {
    font-size: 17px !important;
  }
}
.linkText {
  font-size: 20px;
  border-bottom: 2px solid transparent;
  transition: border-color 0.3s;
}
.refinedLink:hover .linkText {
  border-color: black;
}

.linkArrow2 {
  font-size: 16px !important;
  color: white;
  transition: transform 0.3s;
}
.nextButton3:hover .linkArrow2 {
  transform: translateX(8px);
}
.linkArrow3 {
  font-size: 16px !important;
  color: white;
  transition: transform 0.3s;
}

.nextButton3:hover .linkArrow3 {
  transform: translateX(-8px);
}

.gallery3 img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }


.nextButton3 {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.nextButton3 .image-wrapper3 {
  position: relative;
  transition: all 0.3s;
  cursor: pointer;
}

.nextButton3 .image-wrapper3 img.gallery3 {
  width: 100%;
  height: auto;
  object-fit: cover;
  display: block;
}
.nextButton3 .image-wrapper3 .hover-overlay3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 1;
  transition: opacity 0.3s;
  pointer-events: none;
}
.nextButton3 .image-wrapper3 .hover-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 23px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 1px;
  transition: opacity 0.3s;
  opacity: 1;
}
.nextButton3 .image-wrapper3:hover .hover-text {
  opacity: 0;
}
.nextButton3 .image-wrapper3:hover .hover-overlay3 {
  opacity: 0;
}
.nextButton3 .image-wrapper3:hover img.gallery3 {
  filter: brightness(100%);
  transition: filter 0.3s;
}
@media only screen and (max-width: 768px) {
  .nextButton3 .image-wrapper3 .hover-text {
    opacity: 1 !important;
  }
}
